import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [0,4,2,5];

export const dictionary = {
		"/(storyblok)": [11,[3]],
		"/account/(customer)": [~13,[4]],
		"/account/(customer)/addresses": [~14,[4]],
		"/account/(customer)/addresses/edit/[[addressId]]": [~15,[4]],
		"/account/login": [~18],
		"/account/logout": [~19],
		"/account/(customer)/orders": [~16,[4]],
		"/account/(customer)/orders/[orderNumber]": [~17,[4]],
		"/account/register": [~20],
		"/account/reset": [~21],
		"/account/reset/[hash]": [~22],
		"/(shopware)/auth": [~6,[2]],
		"/(shopware)/bedrijven": [~7,[2]],
		"/(shopware)/bedrijven/[...slug]": [~8,[2]],
		"/checkout": [~23,[5]],
		"/detail/[uuid]": [~24],
		"/navigation/[uuid]": [~25],
		"/order/bedankt": [26],
		"/order/error": [27],
		"/(shopware)/sectoren": [~9,[2]],
		"/(shopware)/sectoren/[...slug]": [~10,[2]],
		"/vermelding": [~28],
		"/vermelding/bedankt": [29],
		"/winkelmand": [30],
		"/(storyblok)/[...slug]": [12,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';